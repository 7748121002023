import { AxiosPromise } from 'axios'

import { ApiService } from '@lasso/api-shared'

import {
  GetSourceAdGroupsRequest,
  GetSourceAdGroupsResponse,
  ValidateSourceAdGroupIdsListRequest, ValidateSourceAdGroupIdsListResponse,
} from '../types/sourceAdGroup'

export class AdGroupService extends ApiService {
  getSourceAdGroups(data: GetSourceAdGroupsRequest): AxiosPromise<GetSourceAdGroupsResponse> {
    return this.request({
      url: 'api/AdGroup/sourceAdGroups',
      method: 'GET',
      params: {
        ...data,
        adGroupIds: 'undefined',
        context: 'act-2',
      },
    })
  }

  validateSourceAdGroupIdsList(data: ValidateSourceAdGroupIdsListRequest): AxiosPromise<ValidateSourceAdGroupIdsListResponse> {
    return this.request({
      url: 'api/adgroup/validatesourceadgroupidslist',
      method: 'POST',
      data,
    })
  }
}
