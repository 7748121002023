import { AxiosInstance } from 'axios'

import { AccountService } from './services/AccountService'
import { AdGroupService } from './services/AdGroupService'
import { AudiencesService } from './services/AudiencesService'
import { CampaignSummaryService } from './services/CampaignSummaryService'
import { InventoryListService } from './services/InventoryListService'
import { LocationsService } from './services/LocationsService'
import { SftpCredentialsService } from './services/SftpCredentialsService'
import { WorkflowConfigService } from './services/WorkflowConfigService'
import { WorkflowCreativeFileService } from './services/WorkflowCreativeFileService'
import { WorkflowExecutionFileService } from './services/WorkflowExecutionFileService'

export class ActivationLegacyApi {
  public readonly account: AccountService
  public readonly adGroup: AdGroupService
  public readonly audiences: AudiencesService
  public readonly campaignSummary: CampaignSummaryService
  public readonly inventoryList: InventoryListService
  public readonly locations: LocationsService
  public readonly sftpCredentials: SftpCredentialsService
  public readonly workflowConfig: WorkflowConfigService
  public readonly workflowCreativeFile: WorkflowCreativeFileService
  public readonly workflowExecutionFile: WorkflowExecutionFileService

  constructor(public readonly request: AxiosInstance) {
    this.account = new AccountService(this.request)
    this.adGroup = new AdGroupService(this.request)
    this.audiences = new AudiencesService(this.request)
    this.campaignSummary = new CampaignSummaryService(this.request)
    this.inventoryList = new InventoryListService(this.request)
    this.locations = new LocationsService(this.request)
    this.sftpCredentials = new SftpCredentialsService(this.request)
    this.workflowConfig = new WorkflowConfigService(this.request)
    this.workflowCreativeFile = new WorkflowCreativeFileService(this.request)
    this.workflowExecutionFile = new WorkflowExecutionFileService(this.request)
  }
}
