import { WorkflowExecutionFile, WorkflowExecutionFileFilters } from './workflowExecutionFile'
import { PageableRequest, PageableResponse } from './Pageable'
import { SortableRequest } from './Sortable'

export enum RunState {
  Active = 'Active',
  Paused = 'Paused',
}

export enum FileDelimiter {
  Comma = ',',
  Pipe = '|',
}

export enum AudienceChannelSource {
  Programmatic = 'Programmatic',
  Meta = 'Meta',
  LinkedIn = 'LinkedIn',
  Email = 'Email',
  SocialFileName = 'SocialFileName',
  Omnichannel = 'Omnichannel',
}

export enum CreativesUsageType {
  Standard = 'Standard',
  DynamicCreative = 'DynamicCreative',
}

export enum AudienceSource {
  Sftp = 'Sftp',
  Other = 'Other',
}

export enum KeycodeMappingSource {
  AudienceID = 'AudienceID',
  Keycode = 'KeyCode',
  KeycodeAndCampaign = 'KeyCodeAndCampaign',
  CreativeID = 'CreativeID',
}

export enum NewFilesBehavior {
  Add = 'AddToPreviousNPIs',
  Replace = 'ReplacePreviousNPIs',
}

export enum AudienceExclusionBehavior {
  None = 'None',
  EmptyNpi = 'EmptyNpi',
  KeyCodeMissing = 'KeyCodeMissing',
}

export type WorkflowConfig = {
  id?: string
  name: string
  marketerId?: number
  marketerName?: string
  creativesUsageType: string
  runState: RunState
  isFullyConfigured: boolean
  advertiserId?: number
  audienceChannelSource: AudienceChannelSource[]
  keycodeMappingSource: string
  npiHeader: string
  audienceHeader: string
  creativeHeader: string
  csvDelimiter: FileDelimiter
  emailTo: string[]
  createdDate?: Date
  lastUploaded?: string
  executionFiles?: WorkflowExecutionFile[]
  isCmiProact?: boolean
  finalized: boolean
  sftpCredentialsSecret: string | null
  audienceSource: AudienceSource
  dataset?: string
  createdTime?: string
  previousNpisType: NewFilesBehavior
  clearAudienceType: AudienceExclusionBehavior
}

export type WorkflowConfigFilters = {
  searchQuery: string
}

export type GetWorkflowConfigsRequest = PageableRequest & SortableRequest<'name' | 'createdTime' | 'lastUploaded'> & WorkflowConfigFilters

export type GetExecutionFilesRequest = PageableRequest & SortableRequest<'name' | 'createdDate'> & WorkflowExecutionFileFilters & {
  workflowConfigId: string
}

export type GetWorkflowConfigResponse = WorkflowConfig

export type GetWorkflowConfigsResponse = PageableResponse & {
  workflowConfigs: WorkflowConfig[]
}

export type GetExecutionFilesResponse = PageableResponse & {
  workflowConfig: WorkflowConfig
}
